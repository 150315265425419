import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import HistoryShopContainer from '@/containers/HistoryShop/HistoryShopContainer';

import { HistoryShopPageQuery } from '../../../graphql-types';

interface Props {
  data: HistoryShopPageQuery;
}

export const pageQuery = graphql`
  query HistoryShopPage {
    prismicHistoryShopPage {
      data {
        meta_title
        meta_description
        ...HistoryShopHero
        ...HistoryShopPhilosophy
        ...HistoryShopFullbleedBg
        ...HistoryShopTimeline
        ...HistoryShopTeam
      }
    }
    prismicHistoryShopPageBody2Companystats {
      primary {
        type
      }
      items {
        number {
          raw
        }
        label {
          text
        }
      }
    }
    prismicHistoryShopPageBody1Contactbox {
      primary {
        type
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
  }
`;

const HistoryShop = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } =
    data?.prismicHistoryShopPage?.data || {};

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'History page'}
        description={meta_description || 'This is our history page'}
      />
      <HistoryShopContainer data={data} type="magasin" />
    </Layout>
  );
};

export default HistoryShop;
