import React from 'react';
import { RichText } from 'prismic-reactjs';

import HistoryShopHero from '@/containers/HistoryShop/HistoryShopHero';
import HistoryShopPhilosophy from '@/containers/HistoryShop/HistoryShopPhilosophy';
import HistoryShopFullbleedBg from '@/containers/HistoryShop/HistoryShopFullbleedBg';
import HistoryShopStats from '@/containers/HistoryShop/HistoryShopStats';

import HistoryShopTeam from '@/containers/HistoryShop/HistoryShopTeam';
import HistoryShopContactBox from '@/containers/HistoryShop/HistoryShopContactBox';

import {
  HistoryShopPageQuery,
  PrismicHistoryShopPagePhilosophyValuesGroupType,
  PrismicHistoryShopPageBody2Companystats,
  PrismicHistoryShopPageBody1Contactbox,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicHistoryShopPage: HistoryShopPageQuery;
    prismicHistoryShopPageBody1Contactbox: PrismicHistoryShopPageBody1Contactbox;
    prismicHistoryShopPageBody2Companystats: PrismicHistoryShopPageBody2Companystats;
  };
  type: string;
}

const HistoryShopContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_background,
    hero_title,
    hero_content,
    philosophy_title,
    philosophy_values,
    timeline_title,
    team_title,
    body,
  } = data?.prismicHistoryShopPage?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  // const { primary } = data?.prismicHistoryPageBody1Contactbox || {};

  const { primary } = data?.prismicHistoryShopPageBody1Contactbox || {};

  const companyStats = data?.prismicHistoryShopPageBody2Companystats || {};

  const HeroProps = {
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
    title: <RichText render={hero_title?.raw} />,
    content: <RichText render={hero_content?.raw} />,
  };

  const PhilosophyProps = {
    title: <RichText render={philosophy_title?.raw} />,
    values: philosophy_values?.map(
      (v: PrismicHistoryShopPagePhilosophyValuesGroupType | null) => ({
        title: <RichText render={v?.title?.raw} />,
        content: <RichText render={v?.content?.raw} />,
      }),
    ),
  };

  const FullbleedBgProps = {
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  // const StatsProps = {
  //   stats: stats?.map((el: PrismicCompanyStatsStatsGroupType | null) => ({
  //     number: <RichText render={el?.number?.raw} />,
  //     label: el?.label,
  //   })),
  // };

  // const StatsProps = companyStats.map((el: any) => {
  //   if (el?.node?.primary?.type === type) {
  //     return {
  //       stats: el?.node?.items?.map((item: any) => ({
  //         number: <RichText render={item?.number?.raw} />,
  //         label: item?.label?.text,
  //       })),
  //     };
  //   }
  //   return {};
  // });

  // const [StatsProps] = companyStats.map((el: any) => {
  //   if (el?.node?.primary?.type === 'usine') {
  //     return {
  //       stats: el?.node?.items?.map((item: any) => ({
  //         number: <RichText render={item?.number?.raw} />,
  //         label: item?.label?.text,
  //       })),
  //     };
  //   }
  // });

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  // const TimelineProps = {
  //   title: <RichText render={timeline_title?.raw} />,
  // };

  const TeamProps = {
    title: <RichText render={team_title?.raw} />,
    sliders: body?.map((slider) => {
      const { slider_title } = slider?.primary || {};

      return {
        sliderTitle: <RichText render={slider_title?.raw} />,
        members: slider?.items?.map((item) => ({
          photo: item?.photo,
          hidden_photo: item?.hidden_photo,
          name: item?.name,
          role: item?.role,
        })),
      };
    }),
  };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  // const renderContactBox = () => {
  //   return ContactBoxesProps?.map((box: any) => {
  //     if (box?.type === type) {
  //       return <HistoryShopContactBox {...box} />;
  //     }
  //     return <div />;
  //   });
  // };

  return (
    <>
      <HistoryShopHero {...HeroProps} />
      <HistoryShopPhilosophy {...PhilosophyProps} />
      <HistoryShopFullbleedBg {...FullbleedBgProps} />
      {/* <HistoryShopStats {...StatsProps} /> */}
      {/* <HistoryShopTimeline {...TimelineProps} /> */}
      <HistoryShopTeam {...TeamProps} />
      {primary && <HistoryShopContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && <HistoryShopStats {...StatsProps} />}
    </>
  );
};

export default HistoryShopContainer;
