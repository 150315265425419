import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';
import Container from '@/components/atoms/Container';
import Background from '@/components/atoms/Background';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  background?: ImageDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
}

const HistoryShopHeroRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    max-width: ${({ theme }) => theme.breakpoints.lg}px;
  }
`;
const LeftSideGrid = styled(Grid)`
  width: 100%;
  order: 2;
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${mq('lg')} {
    order: 1;
    margin-top: 0;
  }
`;
const LeftBackground = styled(Background)`
  height: 100%;
  min-height: 400px;
`;
const RightSideGrid = styled(Grid)`
  order: 1;

  ${mq('lg')} {
    padding-left: ${({ theme }) => theme.spacing(3.5)};
    order: 2;
  }
`;
const Title = styled(Typography)`
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  max-width: 750px;
  margin: auto;

  ${mq('lg')} {
    max-width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
`;
const Content = styled(Typography)`
  display: none;
  margin-top: ${({ theme }) => theme.spacing(1.5)};

  // prismic p wrapping img class
  & .block-img {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }

  & img {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  ${mq('lg')} {
    display: block;
  }
`;

const HistoryShopHero = ({
  background,
  title,
  content,
}: Props): JSX.Element => {
  return (
    <HistoryShopHeroRoot>
      <Grid container alignItems="stretch">
        <LeftSideGrid lg={4}>
          <LeftBackground
            background={background}
            imageProps={{ sizes: '400px' }}
            overlay={0}
          />
        </LeftSideGrid>
        <RightSideGrid xxs={12} lg={8}>
          <Title variant="h1">{title}</Title>
          <Content>{content}</Content>
        </RightSideGrid>
      </Grid>
    </HistoryShopHeroRoot>
  );
};

export const query = graphql`
  fragment HistoryShopHero on PrismicHistoryShopPageDataType {
    hero_background {
      url
      alt
    }
    hero_title {
      raw
    }
    hero_content {
      raw
    }
  }
`;

export default HistoryShopHero;
